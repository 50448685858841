import {
	Entity,
	EntityCode,
	Metadata,
	FileItemProps,
	MetadataAction
} from 'app-types';
import {
	RotationStepTypes,
	RotationStepMainType,
	RotationStepPOIType,
	RetrieveRotationStep,
	CustodyAgentType
} from '../vesselProgramme/vesselProgrammeServiceTypes';
import { BankAccountEntity } from './../bankAccounts/bankAccountsServiceTypes';
import { FetchStatus, ResultCollection } from 'services/api/apiTypes';
import { PortJobStatus } from 'store/portJobs/constants';
import {
	RetrieveDownloadDocumentResponse,
	DocumentContextualizationBaseRequestData
} from 'services/api/documents/documentsServiceTypes';

import {
	PortCallDocument,
	RetrievePortCallDocumentImageRequest,
	RetrievePortCallDocumentImageResponse
} from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';

import { UserType } from '../users/userServiceTypes';
import { DirectBillDiscountType } from '../directBills/directBillsServiceTypes';
import {
	Funding,
	FundingColumnsMetadataKey,
	FundingRequest,
	FundingRequestMetadataActionCode
} from '../financeFundings/financeFundingsServiceTypes';
import { PortJobCancelReason } from '../portJobs/portJobsServiceTypes';
import { ContextBar } from 'store/contextBars/contextBarsTypes';
import {
	FinanceCommentsTabType,
	FinanceDocumentType,
	PageType
} from 'store/finance/constants';
import { PortCallSectionCode } from '../portCall/portCallServiceTypes';

export enum FinanceStatus {
	APPROVED = 'Approved',
	VERIFIED = 'Verified',
	SUBMITTED = 'Submitted',
	QUERIED = 'Queried',
	DRAFT = 'Draft'
}

export enum DaStatus {
	DRAFT = 'Draft',
	SUBMITTED = 'Submitted',
	VERIFIED = 'Verified',
	APPROVED = 'Approved',
	QUERIED = 'Queried',
	VERIFICATION_PENDING = 'VerificationPending',
	APPROVAL_PENDING = 'ApprovalPending',
	HUB_SUBMISSION_PENDING = 'HUBSubmissionPending',
	LPA_SUBMISSION_PENDING = 'LPASubmissionPending',
	PRINCIPAL_SUBMISSION_PENDING = 'PrincipalSubmissionPending'
}

export enum FinanceStage {
	PDA = 'PDA',
	CE = 'CE',
	DA = 'DA',
	CLOSED = 'Closed'
}

export enum ComparisonColumnName {
	LPA = 'lpa',
	USD_AMOUNT = 'usdAmount'
}
export interface FinancePortJobDetails {
	code: string;
	financeStage: FinanceStage;
	financeStatus: FinanceStatus;
	isJobCancelled: boolean;
	jobCancelReason?: PortJobCancelReason;
	jobCancelReasonName: string;
	isOperationsDisabled: boolean;
	financeEnabled: boolean;
}

export interface FinanceActivePortJobDetails extends FinancePortJobDetails {
	isJobCancelled: boolean;
}
export interface FinanceDetails {
	jobs: FinancePortJobDetails[];
}

export enum FinanceMetadataActionCode {
	// Common finance action codes
	VIEW = 'view',
	EDIT = 'edit',
	DELETE = 'delete',
	DISABLE = 'disable',
	ON_TRACK = 'onTrack',
	MOVE = 'move',
	SUBMIT = 'submit',
	VERIFY = 'verify',
	APPROVE = 'approve',
	MOVE_TO_DA = 'moveToDA',
	MOVE_TO_CE = 'moveToCE',
	APPROVE_ALL_SERVICES = 'approveAllServices',
	RETURN_TO_LPA = 'returnToLPA',
	RETURN_TO_HUB = 'returnToHub',
	SUBMIT_WITHOUT_PRINICIPAL_REAPPROVAL = 'submitWithoutPrinicipalReapproval',
	UPLOAD_DOCUMENTATION = 'uploadDocumentation',
	CONVERT_TO_REGULAR_SERVICE = 'convertToRegular',
	CANCEL_REVENUE_INVOICE_TRANSACTION = 'cancelRevenueInvoiceTransaction',
	ADD_COMMENT = 'addComment',
	MOVE_SERVICE = 'moveService',
	REFRESH_DIRECT_BILL_AGREEMENTS = 'refreshDirectBillAgreements',
	SEND_FINANCE = 'sendFinance',
	// Services action codes
	CREATE = 'create',
	CONVERT = 'convert',
	SPLIT = 'split',
	REVERSE_SPLIT = 'reverseSplit',
	QUERY_TO_LPA = 'queryToLpa',
	QUERY_TO_HUB = 'queryToHub',
	RESOLVE_QUERY = 'resolveQuery',
	CONTEXTUALIZE = 'contextualize',
	CONVERT_TO_DIRECT_BILL = 'convertToDirectBill',
	CANCEL_CREDIT_HUB_REVENUE = 'cancelCreditHubRevenue',
	ADD_CREDIT_HUB_REVENUE_SERVICE = 'addCreditHubRevenueService',
	ADD_CREDIT_HUB_REVENUE_SERVICE_CHANGE_REQUESTED = 'addCreditHubRevenueServiceChangeRequested',
	CANCEL_CREDIT_SUPPLIER_INVOICE = 'cancelCreditSupplierInvoice',
	DISABLE_FINANCE = 'disableFinance',
	ADD_UNIT_COST_RATE = 'addUnitCostRate',
	EDIT_UNIT_COST_RATE = 'editUnitCostRate',
	VIEW_UNIT_COST_RATE = 'viewUnitCostRate',
	// DAs action codes
	ADD_SUPPLEMENTAL = 'addSupplemental',
	REOPEN_SET = 'reopenSet',
	ADD_DA_OUT = 'addDaOut',
	DELETE_DA_OUT = 'deleteDaOut',
	DIRECT_BILL_QUERYABLE = 'directBillQueryable'
}

export const AddUnitCostMetaDataKey = {
	[FinanceMetadataActionCode.ADD_UNIT_COST_RATE]: 'addUnitCostRate',
	[FinanceMetadataActionCode.EDIT_UNIT_COST_RATE]: 'editUnitCostRate',
	[FinanceMetadataActionCode.VIEW_UNIT_COST_RATE]: 'viewUnitCostRate'
};

export enum BenchmarkStatus {
	REQUESTED = 'Requested',
	SYNCHRONIZED = 'Synchronized'
}

export enum BenchmarkMetadataActionCode {
	REFRESH = 'refresh'
}

export type FinanceMetadataAction = MetadataAction<FinanceMetadataActionCode>;

export type FinanceMetadata = Metadata<FinanceMetadataActionCode>;

export enum DAColumnMetadataKey {
	DA_NUMBER = 'number',
	CURRENCY = 'currencyCode',
	BANK_DETAILS = 'bankDetail',
	LABEL = 'label',
	INVOICE_NUMBER = 'invoiceNumber',
	NUMBER_OF_SERVICES = 'numberOfServices',
	DA_AMOUNT = 'daAmount',
	DA_OUT_AMOUNT_IN_USD = 'totalAmountInUsd',
	TOTAL_AMOUNT = 'totalAmount',
	FUNDS_RECEIVED = 'fundsReceived',
	FUNDS_ALLOCATED = 'fundsAllocated',
	SETTLEMENT_AMOUNT = 'settlementAmount',
	COVER_SHEET = 'document',
	DOCUMENTATION = 'documentation',
	LIABLE_PARTY = 'liablePartyCompany',
	STATUS = 'status',
	DISABLED_INFO = 'disabledInfo',
	SUBMITTED_ON = 'submittedOn',
	SETTLEMENT_ON = 'settlementOn',
	COMMENTS = 'comments',
	VERIFIED_ON = 'verifiedOn',
	APPROVED_ON = 'approvedOn',
	BLENDED_FX_RATE = 'blendedFxRates',
	HAS_HISTORY = 'hasHistory'
}

export enum DAColumnMetadataActionCode {
	APPROVE = 'approve',
	DELETE = 'delete',
	// Fake action which we create on the front-end side
	EDIT = 'edit'
}

// consider using this as ColumnType inside ServiceColumns.tsx
export enum ServiceColumnsMetadataKey {
	NAME = 'name',
	PRINCIPAL_SERVICE_CODE = 'principalServiceCode',
	AMOUNT = 'amount',
	CURRENCY = 'currencyCode',
	DA_IN = 'daInId',
	EXCHANGE_RATE_DA_IN = 'exchangeRateToDaIn',
	EXCHANGE_RATE_DA_OUT = 'exchangeRateToDaOut',
	DA_IN_INDICATIVE_RATE = 'indicativeRateToDaIn',
	DA_OUT_INDICATIVE_RATE = 'indicativeRateToDaOut',
	DA_IN_AMOUNT = 'amountInDaIn',
	DA_OUT = 'daOutId',
	DA_OUT_AMOUNT = 'amountInDaOut',
	AMOUNT_USD = 'amountInUsd',
	DOCUMENT = 'document',
	STATUS = 'status',
	COMMENT = 'comment',
	INFO = 'info',
	PDA_AMOUNT_USD = 'amountInUsdPDA',
	CE_AMOUNT_USD = 'amountInUsdCE',
	PDA_DA_IN_AMOUNT = 'amountInDaInPDA',
	CE_DA_IN_AMOUNT = 'amountInDaInCE',
	COMPARISON_AMOUNT_IN_DA_IN = 'comparisonAmountInDaIn',
	FUNDS = 'funds'
}

export enum BenchmarkColumnsMetadataKey {
	COMMENT = 'comment'
}

export interface CollectionView<
	Element,
	Props = string,
	Action = FinanceMetadataActionCode
> {
	propertiesToView: Props[];
	elements: Element[];
	metadata?: Metadata<Action, Props> | null;
}

export interface MultilevelApprovalSettings {
	currentApprovalLevel: number;
	requiredApprovalLevels: number;
}

export interface BenchmarksItem {
	id: string;
	serviceCategoryName: string;
	comment: string;
	daAmount: number;
	daAmountInUsd: number;
	benchmarkAmountInUsd: number;
	exchangeVariance?: number;
	spendVariance: number;
	totalVariance: number;
	metadata?: Metadata<[], BenchmarkColumnsMetadataKey>;
}

export interface BenchmarksData {
	benchmarkSynchronizationStatus: BenchmarkStatus | null;
	isBenchmarkJob: boolean;
	daId?: string;
	items: BenchmarksItem[];
	metadata: Metadata<BenchmarkMetadataActionCode>;
	id?: string;
}

export interface PortCallFinance {
	id: string;
	job: Job;
	financeStage: FinanceStage;
	financeStatus: FinanceStatus;
	financeEnabled?: boolean;
	updatedOn: string;
	// TODO: We should create map for it and move DAs logic in seperate part of story
	disbursementAccountIns: CollectionView<
		DisbursementAccount,
		DAColumnMetadataKey
	>;
	disbursementAccountOuts: CollectionView<
		DisbursementAccount,
		DAColumnMetadataKey
	>;
	disbursementAccountSets: DaSet[];
	serviceSections: ServiceSection[];
	services: CollectionView<PortJobService>;
	portCurrencies: string[];
	exchangeRates?: ExchangeRate[];
	indicativeRateDate: string;
	fixedRateDateDA?: string;
	fixedRateDateSDA?: string;
	approvalSettings: ApprovalSettings;
	daInTotalAmountInUsd: number | null; // `null` in case of Principal
	daOutTotalAmountInUsd: number | null; // `null` in case of LPA
	readonly metadata: Metadata<FinanceMetadataActionCode>;
	readonly metadataVP: Metadata<FinanceMetadataActionCode>;
	queriedToLPA: boolean;
	queriedToHub: boolean;
	/** sorted by uploadedOn, included only with expand=additionalDocuments request */
	additionalDocuments: FinanceAdditionalDocument[];
	// Job finance status calculated on BE
	displayStatus: string;
	contextBars?: ContextBar[];
	multilevelApprovalSettings?: MultilevelApprovalSettings;
	commentsInfo?: CommentsInfo;
	benchmarkData: BenchmarksData;
	invoices?: CollectionView<Invoice>;
	isSameCurrencyDA?: boolean;
	isSameCurrencySDA?: boolean;
	isFixedRateDA?: boolean;
	isFixedRateSDA?: boolean;
	fixedRates?: FixedRate[];
	isOperationsDisabled?: boolean;
	rotationSteps?: RetrieveRotationStep[];
	isIndicativeRateOverride?: boolean;
	portJobServiceVarianceComment: PortJobServiceVarianceComment;
}

interface CommentsInfo {
	hasComments: boolean;
	hasUnreadComments: boolean;
}
export enum FinanceAdditionalDocumentActionCode {
	DELETE = 'delete'
}

export type FinanceAdditionalDocumentAction = MetadataAction<
	FinanceAdditionalDocumentActionCode
>;

export interface FinanceAdditionalDocument extends FileItemProps {
	metadata: Metadata<FinanceAdditionalDocumentActionCode>;
}

export interface ApprovalSettings {
	isPDAApprovalRequired: boolean;
	isCEApprovalRequired: boolean;
	isDAApprovalRequired: boolean;
	isPDABypassPrinicipalReApprovalRequired: boolean;
}

export interface MoveJobFinanceResponse {}
export interface ValidateJobFinanceResponse {}
export interface MoveToStageJobFinanceResponse {}
export interface ApproveAllServicesResponse {}

export interface FinanceRequest {
	portCallId: string;
	jobCode: string;
}

export interface RetrieveServiceRequest extends FinanceRequest {
	jobServiceId: string;
}

export interface RetrieveSDAReasonRequest {
	userType: UserType;
}

export interface SDAReasons extends EntityCode {
	userType: UserType;
}

export interface UpdateSDAReasonRequest extends FinanceRequest {
	disbursementAccountSetId: string;
	sdaReason: string;
	userType: UserType;
}

export type UpdateFinanceServiceRequest = Partial<PortJobService>;

export interface UpdateFinanceStatusPayload {
	action: string;
	comment: string;
}

export interface UpdateFinanceStatusRequest extends UpdateFinanceStatusPayload {
	jobCode: string;
	portCallId: string;
}

export interface FinanceRequest {
	portCallId: string;
	jobCode: string;
}

export interface RetrieveFinanceDetailsRequest {
	portCallId: string;
}

export interface MoveServiceSection {
	code: ServiceSectionCode;
	name: string;
	entityId?: string;
}

export interface SplitMoveServiceSection {
	code: ServiceSectionCode;
	name: string;
	entityId?: string;
	sectionType?: PortCallSectionCode;
}

export interface MoveServicesSection {
	code: ServiceSectionCode;
	name: string;
	entityId?: string;
}

export interface RetrieveAvailableSectionCodeResponse {
	financeAllSection: MoveServiceSection[];
}

export interface MoveServiceRequest extends FinanceRequest {
	sectionCode: ServiceSectionCode;
	serviceCode: string;
	jobServiceId: string;
	entityId?: string;
}

export enum JobFinanceExpandParam {
	METADATA = 'metadata',
	ADDITIONAL_DOCUMENTS = 'additionalDocuments'
}

export interface RetrieveFinanceRequest extends FinanceRequest {
	isSilent?: boolean;
	expand?: JobFinanceExpandParam[];
	benchmarkData?: BenchmarksData;
}

export interface RetrieveFinanceResponse extends PortCallFinance {
	fundingRequests?: CollectionView<
		FundingRequest,
		string,
		FundingRequestMetadataActionCode
	>;
	// funding requests which were approved and became transactions
	fundings?: CollectionView<Funding, FundingColumnsMetadataKey>;
}

export interface MoveToStagePayload {
	// currenlty supports CE and DA
	stage: FinanceStage;
}
export interface MoveJobFinanceRequest extends FinanceRequest {
	isSubmitWithoutPrinicipalReApproval?: boolean;
}

export interface ValidateSDAReasonRequest extends FinanceRequest {
	sdaReason?: string;
}

export type ValidateJobFinanceRequest = ValidateSDAReasonRequest;

export interface MoveToStageJobFinanceRequest extends FinanceRequest {
	stage: FinanceStage;
}
export type RevertJobStatusRequest = FinanceRequest;

export enum FinanceServiceErrorType {
	INWARDS = 'Inwards',
	OUTWARDS = 'Outwards'
}

export interface FinanceServiceError {
	code: string;
	name: string;
	errorType: FinanceServiceErrorType;
}

export type RetrieveServiceErrorsResponse = ResultCollection<
	FinanceServiceError
>;

export interface CurrencyBasicType {
	code: string;
	decimalPlaces: number;
}

export type RetrieveFinanceCurrenciesResponse = ResultCollection<
	CurrencyBasicType
>;

export interface FinanceServiceQueries {
	lpaQueries?: ServiceStatusMessage;
	principalQueries?: ServiceStatusMessage;
}
export interface RetrieveServiceQueriesRequest extends FinanceRequest {
	jobServiceId: string;
}

export enum DAOutValidationMode {
	OFFLINE_UPLOAD = 'OfflineUpload',
	OFFLINE_EMAIL = 'OfflineEmail',
	ONLINE = 'Online'
}

/**
 * TODO: Review naming. Both All AddDA operation should share the same interface
 */
export interface AddDAOutRequestPayload extends Partial<DisbursementAccount> {}

export interface AddDAActionPayload {
	daType: DaType;
	data: AddDAOutRequestPayload;
}
interface AddDARequestPayload {
	label: string;
	currencyCode: string;
	invoiceNumber?: string;
	daType: DaType | null;
}
export interface AddDARequest extends FinanceRequest {
	payload: AddDARequestPayload;
}

export interface AddDAOutRequest extends FinanceRequest {
	serviceId: PortJobService['id'];
}

export type AddDAOutResponse = PortJobService;

export type DeleteDAOutRequest = AddDAOutRequest;

export type DeleteDAOutResponse = AddDAOutResponse;

export interface AddFinanceComments extends FinanceRequest {
	payload: FinanceCommentsData;
}

export interface UpdateDAOutActionPayload {
	daType: DaType;
	disbursementAccountId: string | undefined;
	data: AddDAOutRequestPayload;
}
export interface UpdateDARequest extends FinanceRequest {
	disbursementAccountId: string;
	payload: AddDARequestPayload;
}

export interface UpdateDAResponse extends Partial<AddDARequestPayload> {
	daType: DaType;
}

export interface SearchApprovalGroupsRequest extends FinanceRequest {
	searchTerm: string;
}

export type AddDAResponse = DisbursementAccount;

export interface DeleteDAActionPayload {
	id: string;
}
export interface DeleteDARequest extends FinanceRequest {
	id: string;
}

export interface ReopenDaRequest extends FinanceRequest {
	daSetId: string;
}

export interface PortJobDAs {
	id: string;
	name: string;
}

export interface Job {
	code: string;
	custodyAgentRole: CustodyAgentType;
	jobType: string;
	performingAgentCompany: PerformingAgentCompany;
	appointerCompany: Entity;
	nominatorCompany: Entity;
	status: PortJobStatus;
	daAssigned: Entity;
	daAssignedGroup: string[];
	portJobDAs: PortJobDAs[];
}

interface PerformingAgentCompany {
	id: string;
	name: string;
}

export interface CurrencyPair {
	baseCurrencyCode: string;
	quoteCurrencyCode?: string;
}
export interface ExchangeRate extends CurrencyPair {
	date: string;
	indicativeRate: string;
}

export interface CurrencyPairDictionary {
	[pair: string]: CurrencyPair;
}

export interface FixedCurrencyDictionary {
	[pair: string]: FixedRate;
}

export interface CurrencyPairExchangeRateDictionary {
	[pair: string]: ExchangeRate;
}

export interface FinanceDoc {
	id: string;
	pagesCount: number;
	isSupplierInvoice: boolean;
	isPdfSplitPages: boolean;
	isAnnotated?: boolean;
}

export interface BlendedFXRate {
	currencyCode: string;
	rate: number;
}

export interface FixedRate {
	rate: string;
	currencyCode: string;
	outCurrencyCode: string;
	lastUpdatedOn?: string;
	disbursementAccountSetId: string;
}

export interface UpdatedFixedRate {
	currencyCode: string;
	lastUpdatedOn: string;
	rate: string;
}

export interface DisbursementAccount {
	id: string;
	number: number;
	label: string;
	currencyCode: string;
	invoiceNumber: string;
	fundsReceived: string | null;
	daType: DaType;
	status: DaStatus;
	isSuplemental: boolean;
	services: string[];
	liablePartyCompany?: Entity;
	processingPartyCompany?: Entity;
	validationMode: DAOutValidationMode;
	validationEmail: string;
	validationGroup?: Entity;
	disbursementAccountSetId: string;
	metadata: Metadata<DAColumnMetadataActionCode>;
	document?: FinanceDoc;
	submittedOn: string | null;
	verifiedOn: string | null;
	verifierName: string | null;
	comment?: string;
	approvedOn: string | null;
	settlementOn: string | null;
	bankDetail?: BankAccountEntity;
	showAlert?: boolean;
	queriedToLPA: boolean;
	queriedToHub: boolean;
	hasPendingAlerts: boolean;
	transactions: FinanceTransaction[];
	totalAmount?: number;
	totalAmountInUsd?: number;
	preferredUSDConversionRate?: string;
	indicativeRateOverrideValue?: string | null;
	blendedFxRates: BlendedFXRate[];
	hasServicesWhichContradictCurrencyLogic?: boolean;
	hasHistory?: boolean;
	approverNames: string[];
	isFixedRateDA: boolean;
	fixedRate?: UpdatedFixedRate;
}

export enum DaUnavailabilityReason {
	NO_PRINCIPAL_PERMISSION = 'NoPermissionsForPrincipal',
	CURRENCY_NOT_VALID = 'CurrencyNotValid',
	STATUS_NOT_VALUE_FOR_LPA = 'StatusNotValidForLPA',
	STATUS_NOT_VALUE_FOR_HUB = 'StatusNotValidForHub',
	STATUS_NOT_VALUE_FOR_PRINCIPAL = 'StatusNotValidForPrincipal'
}
export interface DaAvailabilityItem {
	id: string;
	canBeSelected: boolean;
	reasonCode: DaUnavailabilityReason;
}

export enum DaSetType {
	ORIGINAL = 'Original',
	SUPPLEMENTAL = 'Supplemental'
}

export interface DaSet {
	id: string;
	name: string;
	number: number;
	status: DaSetStatus;
	type: DaSetType;
	metadata: Metadata<FinanceMetadataActionCode>;
	isFixedRate: boolean;
	isSameCurrency: boolean;
	fixedRates: FixedRate[];
	lastUpdatedDate: string;
	lpaReasonCode?: string;
	hubReasonCode?: string;
}

export enum DaSetStatus {
	ACTIVE = 'Active',
	LOCKED = 'Locked',
	FUTURE = 'Future'
}

export enum DaType {
	IN = 'DaIn',
	OUT = 'DaOut',
	LABEL = 'Label'
}
export enum ViaValue {
	EMAIL = 'Email',
	UPLOAD = 'Upload'
}

export enum PrefUSDRate {
	INDICATIVE_RATE = 'IndicativeRate',
	INDICATIVE_RATE_OVERRIDE = 'IndicativeRateOverride'
}

interface DaDocument {
	documentId: string;
	fetchStatus: FetchStatus;
}

export interface DocumentByDaOutId {
	[daOutId: string]: DaDocument;
}

export interface DAStatusHistoryItem {
	id: string;
	createdOnUtc: string;
	financeStage: FinanceStage;
	status: DisbursementAccount['status'];
	queriedToHub?: DisbursementAccount['queriedToHub'];
	queriedToLPA?: DisbursementAccount['queriedToLPA'];
	userRole: UserType;
	userName?: string;
}

export interface RetrieveDAOutDocumentIdRequest {
	portCallId?: string; // Optional, since can be inferred from activePortCallId
	jobCode?: string;
	daOutId: string;
}
export interface RetrieveDAInCoverSheetRequest {
	portCallId?: string; // Optional, since can be inferred from activePortCallId
	jobCode?: string;
	daInId: string;
	documentId?: string;
}
export interface RetrieveDAOutDocumentIdResponse {
	documentId: string;
}

export interface RetrieveDAOutCoverSheetRequest
	extends RetrieveDAOutDocumentIdRequest {
	documentId: string;
}
export interface RetrieveDAOutCoverSheetResponse
	extends RetrieveDownloadDocumentResponse {}

export interface RetrieveDAInCoverSheetResponse
	extends RetrieveDownloadDocumentResponse {}

export interface AddServicePayload {
	serviceCode: string;
	serviceSectionCode: ServiceSectionCode;
	entityId?: string;
}
export interface AddServiceRequest extends FinanceRequest {
	payload: AddServicePayload;
}
export type AddServiceResponse = PortJobService;

export interface DeleteServiceRequest extends FinanceRequest {
	jobServiceId: string;
}
export interface DeleteServiceResponse {}

export interface ServiceTemplate {
	code: string;
	name: string;
}
export interface RetrieveAvailableServicesRequest extends FinanceRequest {
	sectionCode: ServiceSectionCode;
	entityId?: string;
	action: FinanceMetadataActionCode;
	portJobServiceType?: ServiceType;
	isOperationsEnabledRequested: boolean;
}
export type RetrieveAvailableServicesResponse = ResultCollection<
	ServiceTemplate
>;

// Service Sections
// ---------------------
export enum ServiceSectionCode {
	FUNDING = 'Funding',
	GENERAL_PORT = 'GeneralPort',
	VESSEL_PROGRAMME = 'VesselProgramme',
	OPERATION = 'Operation',
	AGENCY = 'Agency',
	HUSBANDRY = 'Husbandry'
}

export enum ServiceStatus {
	DRAFT = 'Draft',
	SUBMITTED = 'Submitted',
	VERIFIED = 'Verified',
	APPROVED = 'Approved',
	QUERIED = 'Queried',
	DELETED = 'Deleted'
}

export interface ServiceTax {
	percentage: number;
	amount: number;
}

export interface ServiceSection {
	id: string;
	code: ServiceSectionCode;
	principalServiceCode?: string;
	principalServiceName?: string;
	entityId?: string;
	name: string;
	services: string[];
	rotationStep: string;
	parentService: string;
	isDirectBill: boolean;
	isOverriden: boolean;
	isWorldScale: boolean;
	sections: SubSection[];
	metadata: Metadata<FinanceMetadataActionCode>;
}

export type FinanceSectionCode = ServiceSectionCode | RotationStepTypes;

export interface SubSection {
	code: RotationStepTypes;
	name: string;
	entityId?: string;
	services: string[]; // list of PortJobService ids associated to RotationStepId
	rotationStepType: RotationStepMainType;
	poiType: RotationStepPOIType;
	metadata: Metadata<FinanceMetadataActionCode>;
}

export interface SimpleSection {
	code: ServiceSectionCode;
	name: string;
	services: string[];
	id: string;
	entityId?: string;
}

export enum ServiceDirectBillTargetType {
	ISS = 'ISS',
	LPA = 'LPA',
	PRINCIPAL = 'Principal'
}
export interface ServiceDirectBillData {
	targetTypeCode: ServiceDirectBillTargetType;
	discountTypeCode: DirectBillDiscountType;
	discountValue: number;
	appointmentInstruction?: string;
	vendorCompanyName: string;
}

export interface ServiceUnitCostRateData {
	id: string;
	crewCount: number | string;
	primaryUnits: number | string;
	secondaryUnits: number | string;
	serviceName: string;
	serviceType: string;
	unitRate: number;
	serviceAmount: number | string;
}

export enum ServiceType {
	REGULAR = 'Regular',
	DIRECT_BILL = 'DirectBill'
}

export enum ServiceUnitCostRateType {
	PRIMARY_UNITS = 'primaryUnits',
	SECONDARY_UNITS = 'secondaryUnits',
	UNIT_RATE = 'unitRate'
}

export interface PortJobService {
	id: string;
	amount: string | number | null;
	tax?: ServiceTax;
	currencyCode: string;
	daInCurrencyCode: string;
	daInId: string;
	daInLabel?: string;
	daOutId: string;
	status: ServiceStatus;
	exchangeRateToDaIn?: string | null;
	exchangeRateToDaOut?: string | null;
	comment?: string;
	code: string;
	name: string;
	isCredit: boolean;
	amountInDaIn: string | number;
	amountInDaOut: string | number;
	amountInUsd: string | number;
	amountInUsdCE: string | number;
	amountInUsdPDA: string | number;
	principalServiceCode?: string;
	principalServiceName?: string;
	serviceSection: {
		code: ServiceSectionCode;
	};
	parentServiceId?: string | null;
	childServiceId?: string | null;
	metadata: Metadata<FinanceMetadataActionCode>;
	document?: FinanceDoc;
	queriedToHub: boolean;
	queriedToLPA: boolean;
	messageCount: number;
	directBill?: ServiceDirectBillData;
	serviceTypeCode: ServiceType;
	transactions: FinanceTransaction[];
	isDaOutDeleted: boolean;
	modeCode?: 'Split' | 'Converted';
	negativeAmountAllowed: boolean;
	isRevenue: boolean;
	isIndicativeRateOverride?: boolean;
	serviceUnitCostRate?: ServiceUnitCostRateData;
}

export interface SplitChildServices extends PortJobService {
	sectionEntityId?: string;
}

export interface Invoice {
	id: string;
	referenceNumber: string;
	transactionsDetails: FinanceTransaction[];
	isSplit?: boolean;
}

export interface FinanceTransaction {
	name: string;
	amount?: string;
	currencyCode?: string;
	status: FinanceTransactionStatus;
	docNumber: string;
	docType: string;
	requestedOn: string;
	repliedOn: string;
	isRevertingTransaction: boolean;
	type?: FinanceTransactionType;
}

export enum FinanceTransactionStatus {
	NEW = 'New',
	PENDING = 'Pending',
	IN_TRAY = 'InTray',
	COMPLETED = 'Completed',
	FAILED = 'Failed'
}

export enum FinanceTransactionType {
	SUPPLIER_INVOICE_POST = 'SupplierInvoicePost',
	SUPPLIER_INVOICE_SPLIT = 'SupplierInvoiceSplit',
	SUPPLIER_INVOICE_REVERSE_SPLIT = 'SupplierInvoiceReverseSplit',
	SUPPLIER_INVOICE_PAYMENT_NOTIFICATION = 'SupplierInvoicePaymentNotification1'
}

export type ServiceStatusAction =
	| FinanceMetadataActionCode.APPROVE
	| FinanceMetadataActionCode.SUBMIT
	| FinanceMetadataActionCode.QUERY_TO_HUB
	| FinanceMetadataActionCode.QUERY_TO_LPA
	| FinanceMetadataActionCode.RESOLVE_QUERY
	| FinanceMetadataActionCode.VERIFY;

export enum QueryEventTypeCode {
	SYSTEM_EVENT = 'SystemEvent',
	MESSAGE_ADDED = 'MessageAdded'
}
export interface QueryEvent {
	eventTypeCode: QueryEventTypeCode;
	message: string;
	createdByUserType: UserType;
	createdOnUtc: string;
}

export interface ServiceStatusMessage {
	newMessage: string;
	serviceErrorCode: string;
	events?: QueryEvent[];
}

export interface FinanceComments {
	tabCode: FinanceCommentsTabType;
	message: string;
	createdByUserType: UserType;
	createdOnUtc: string;
}
export interface FinanceCommentsResponse {
	elements: FinanceComments[];
}

export interface FinanceServiceQuery {
	lpaQuery?: ServiceStatusMessage;
	principalQuery?: ServiceStatusMessage;
}
export interface FinanceCommentsData {
	messageForLpaTab: string;
	messageForPrincipalTab: string;
}
export interface UpdateServiceStatusPayload {
	action: ServiceStatusAction;
	jobServiceId: string;
	queries?: FinanceServiceQuery;
}

export interface UpdateServiceStatusRequest {
	portCallId: string;
	jobCode: string;
	jobServiceId: string;
	action: ServiceStatusAction;
	queries?: FinanceServiceQuery;
}

export interface UpdateBenchmarkActionPayload {
	id: string;
	comment: string;
}

export interface UpdateBenchmarkRequest extends UpdateBenchmarkActionPayload {
	portCallId: string;
	jobCode: string;
}

// convert service
export interface ConvertServicePayload {
	jobServiceId: string;
}
export interface ConvertServiceRequest {
	portCallId: string;
	jobCode: string;
	jobServiceId: string;
}
export type CancelServiceTransactionPayload = ConvertServicePayload;
export type CancelServiceTransactionRequest = ConvertServiceRequest;
export type CancelCreditHubRevenuePayload = ConvertServicePayload;
export type CancelCreditHubRevenueRequest = ConvertServiceRequest;

// Finance Revisions
// ---------------------
export interface PortCallFinanceRevision {
	id: string;
	createdOn: string;
	createdOnUtc: string;
	number: number;
	financeStage: FinanceStage;
	financeStatus: FinanceStatus;
	daInTotalAmountInUsd: number | null; // null if user is not either Hub or LPA
	daOutTotalAmountInUsd: number | null; // null is user is not either Hub or Principal
}

export interface RetrieveFinanceRevisionsRequest {
	portCallId: string;
	jobCode: string;
}

export interface RetrieveFinanceRevisionsResponse
	extends ResultCollection<PortCallFinanceRevision> {}

// Split Services
// ---------------------
interface SplitServiceRequestBase {
	portCallId: string;
	jobCode: string;
	serviceId: string;
}

export interface SplitServiceRequest extends SplitServiceRequestBase {
	payload: SplitServiceRequestPayload;
}

export type ReverseSplitServiceRequest = SplitServiceRequestBase;

export interface SplitServiceRequestPayload {
	childServices: SplitServiceItem[];
}

export interface SplitServiceItem {
	serviceId?: string;
	serviceCode: string;
	sectionCode?: string;
	entityId?: string;
	amountInDaIn?: number | string;
	amount?: number | string;
	shouldMoveService?: boolean;
}
export interface SplitServiceUIItem extends SplitServiceItem {
	uid: string;
	name: string;
	id?: string;
	status?: ServiceStatus;
	sectionCode?: ServiceSectionCode;
	entityId?: string;
	sectionType?: string;
}

export type SplitServiceResponse = ResultCollection<PortJobService>;
export type ReverseSplitResponse = SplitServiceResponse;

export type RetrievePrincipalServicesRequest = RetrieveServiceRequest;
export interface RetrievePrincipalServicesPayload {
	jobServiceId: string;
}

export type RetrievePrincipalServicesResponse = ResultCollection<EntityCode>;

// Document Contextualization
// ---------------------
export interface FinanceService {
	id: string;
	name: string;
	poiDescription: string;
	amount: number;
	currency: string;
	document?: {
		pagesCount: number;
	};
}

export interface FinanceContextualizationDisbursementAccount {
	id: string;
	currency: string;
	servicesCount: number;
	label: string;
	metadata: Metadata<FinanceMetadataActionCode>;
	coverSheet?: {
		pagesCount?: number;
		metadata: Metadata<FinanceMetadataActionCode>;
	};
	serviceCategories: FinanceServiceCategory[];
}

export interface FinanceServiceCategory {
	name: string;
	services: FinanceService[];
}

export interface RetrieveFinanceServicesForContextualizationRequest {
	portCallId: string;
	jobCode: string;
}

export interface RetrieveFinanceServicesForContextualizationResponse {
	das: FinanceContextualizationDisbursementAccount[];
}

// Create Documents
// ---------------------
export interface CreateFinanceDocumentsRequest {
	portCallId: string;
	jobCode: string;
	data: DocumentContextualizationBaseRequestData<
		FinanceDocumentContextualization
	>;
}

export interface FinanceDocumentContextualization {
	number?: number; // only for PDF
	das: string[];
	services: string[];
}

// Retrieve Document
// ---------------------
export interface RetrieveFinanceDocumentRequest
	extends Partial<FinanceRequest> {
	id: string;
	documentType: FinanceDocumentType;
}
export type RetrieveFinanceDocumentResponse = PortCallDocument;

// Retrieve Document Image
// ---------------------

export type RetrieveFinanceDocumentImageRequest = RetrievePortCallDocumentImageRequest;
export type RetrieveFinanceDocumentImageResponse = RetrievePortCallDocumentImageResponse;

// Delete Document
// ---------------------
export interface DeleteFinanceDocumentRequest extends Partial<FinanceRequest> {
	documentId: string;
}

// Download Document
// ---------------------
export interface DownloadFinanceDocumentRequest {
	documentId: string;
	portCallId?: string;
	isMultiFileDownload?: boolean;
}

// Download Revision Documents
// ---------------------
export interface DownloadJobFinanceRevisionRequest {
	portCallId: string;
	jobCode: string;
	revisionId: string;
	disbursementAccountType: DaType;
}

// Download Single DA
// ---------------------
export interface DownloadSingleDARequest extends Partial<FinanceRequest> {
	disbursementAccountId: string;
}
export interface DownloadSingleDAResponse extends DownloadSingleDARequest {}

// Download All DAs
// ---------------------
export interface DownloadAllDAsRequest extends Partial<FinanceRequest> {
	disbursementAccountType: DaType;
}
export interface DownloadAllDAsResponse extends Partial<FinanceRequest> {}

// Delete Document Page
// ---------------------

export interface DeleteFinanceDocumentPageRequest {
	portCallId: string;
	documentId: string;
	actualNumber: number;
	pageType: PageType;
}

export interface ConvertDBStatusRequest extends FinanceRequest {
	comment: string;
	jobServiceId: string;
}

export interface FinanceSubmitMenuItem {
	label: string;
	action: FinanceMetadataActionCode;
}

export interface RetrieveAvailableDasRequest extends FinanceRequest {
	daType: DaType;
	jobServiceId: string;
}

export interface AddCreditHubRevenueServiceRequest extends FinanceRequest {
	jobServiceId: string;
	isCreditHubRevenueChangeRequested?: boolean;
}
export type AddCreditHubRevenueServiceResponse = PortJobService;

export interface RetrieveDAStatusHistoryByIdRequest extends FinanceRequest {
	daId: string;
}

export type RetrieveDAStatusHistoryByIdResponse = ResultCollection<
	DAStatusHistoryItem
>;

export interface SetComparisonColumnRequest {
	serviceFieldName: string;
	columnName: ComparisonColumnName;
}

export interface RefreshBenchmarkRequest extends FinanceRequest {}
export interface RefreshBenchmarkResponse {}

export interface ViewBenchmarkRequest extends FinanceRequest {
	benchmarkId: string;
}
export interface ViewBenchmarkResponse {
	url: string;
}

export interface RetrieveBenchmarkStatus {
	benchmarkSynchronizationStatus: BenchmarkStatus;
}

export interface ToggleFinanceRequest extends ToggleFinancePayload {
	portCallId: string;
	jobCode: string;
}

export interface ToggleFinancePayload {
	isFinanceEnabled: boolean;
}

export interface RetrieveRefreshDirectBillRequest extends FinanceRequest {
	arrivalDate: string;
}

export interface RetrieveRefreshDirectBillResponse {
	directBillsMastersResponse: DirectBill[];
	directBillsFinanceResponse: DirectBill[];
}

export interface RefreshDirectBill {
	currentDirectBills: DirectBill[];
	availableDirectBills: DirectBill[];
}

export interface DirectBill {
	billId: string;
	vendorCompanyName: string;
	serviceName: string;
	isInvoiceUploaded?: boolean;
	isActive: boolean;
	comments?: string;
	entityId: string;
	serviceCode: string;
	serviceSectionCode: string;
	jobFinanceServiceId: string;
}
interface PayloadDirectBill {
	portCallId: string;
	jobCode: string;
	entityId: string;
	serviceCode: string;
	serviceSectionCode: string;
	status: string;
}
export interface UpdateRefreshDirectBillPayload extends FinanceRequest {
	portJobServiceCreateRequestList: PayloadDirectBill[];
}

export interface UpdateRefreshDirectBillRequest extends FinanceRequest {
	payload: UpdateRefreshDirectBillPayload;
}

export interface UpdateRefreshDirectBillResponse {
	id: string;
}

export interface UpdateFixedRateExchangeRequest extends FinanceRequest {
	isFixedRate: boolean;
	daType: string;
	exchangeRates: FixedRate[];
}

export interface UpdateFixedRateExchangePayload {
	isFixedRate: boolean;
	daType: string;
	exchangeRates: FixedRate[];
}

// Retrieve Supplier-Invoice Document Image
// ---------------------
export interface RetrieveSupplierInvoiceDocumentImageRequest {
	documentId: string;
	page: number;
}

export type RetrieveSupplierInvoiceDocumentImageResponse = RetrievePortCallDocumentImageResponse;

export interface SubmitUnitCostFormPayload {
	serviceName: string;
	serviceType: string;
	primaryUnits: string;
	secondaryUnits: string;
	crewCount: string;
	unitRate: string;
	serviceAmount: string;
}

export interface SubmitUnitCostForm {
	jobServiceId: string;
	formValues?: SubmitUnitCostFormPayload;
}

export interface SubmitUnitCostFormResponse {}

export type RestoreOriginalFinanceDocumentRequest = RetrieveFinanceDocumentRequest;

export interface DeleteFinanceDocumentOriginalAndAnnotatedPagesRequest
	extends FinanceRequest {
	documentId: string;
	actualNumber: number;
	documentType: FinanceDocumentType;
}

export interface SubmitUnitCostFormRequest {
	portCallId: string;
	portJobCode: string;
	serviceId: string;
	payload: SubmitUnitCostFormPayload;
}

export interface DeleteUnitCostFormRequest {
	portCallId: string;
	portJobCode: string;
	serviceId: string;
	unitCostRateId: string;
}

export interface UpdateUnitCostFormPayload extends DeleteUnitCostFormRequest {
	payload: SubmitUnitCostFormPayload;
}

export interface UpdateUnitCostForm extends SubmitUnitCostForm {
	id: string;
}
export interface UpdateUnitCostFormResponse {}

export interface DeleteUnitCostForm {
	jobServiceId: string;
	id: string;
	vendorAmountChange: boolean;
}

export interface DeleteUnitCostFormResponse {}

export interface CostImpactDrivers {
	id: string;
	title: string;
}

export interface PortJobServiceVarianceComments {
	id: string;
	jobFinanceId: string;
	portJobServiceId: string;
	comment: string;
	earlierStage: string;
	earlierAmount: number;
	earlierCurrencyCode: string;
	atPresentStage: string;
	atPresentAmount: number;
	atPresentCurrencyCode: string;
	commentedBy: string;
	createdOn: string;
	createdOnUtc: string;
	costImpactDrivers: string[];
}
export interface PortJobServiceVarianceComment {
	portJobServiceVarianceComments: PortJobServiceVarianceComments[];
	costImpactDrivers: CostImpactDrivers[];
}

export interface CreateVarianceCommentPayload {
	jobFinanceId: string;
	portJobServiceId: string;
	comment: string;
	earlierStage: string;
	earlierAmount: number;
	earlierCurrencyCode?: string;
	atPresentStage: string;
	atPresentAmount: number;
	atPresentCurrencyCode?: string;
	commentedBy: string;
	costImpactDriverIds: string[];
}
export type CreateVarianceCommentResponse = null;

export interface CreateVarianceCommentRequest {
	payload: CreateVarianceCommentPayload;
}
