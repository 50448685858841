import { FinanceState } from '../financeState';
import { makeFetchStatusReducers } from 'utils/reducers/fetchStatus';
import {
	CreateVarianceCommentPayload,
	CreateVarianceCommentResponse
} from 'services/api/finance/financeServiceTypes';

export const onAddVarianceComment = makeFetchStatusReducers<
	FinanceState,
	'fetchStatuses',
	CreateVarianceCommentPayload,
	CreateVarianceCommentResponse
>('fetchStatuses', 'addVarianceComment');
