import React, { createContext, useContext, useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { getAuthActAs } from 'store/auth/selectors';
import { getPortCallData } from 'store/portcalls/selectors';

export const UseViewModeContext = createContext({
	isViewMode: false
});

export const useViewModeContext = () => useContext(UseViewModeContext);

export const ViewModeProvider: React.FunctionComponent = ({ children }) => {
	const authActAs = useSelector(getAuthActAs);
	const portCallData = useSelector(getPortCallData);

	const [isViewMode, setIsViewMode] = useState(false);

	useEffect(() => {
		/** Hub users acting as LPAs
		 * Won't have access to VP and Finance pages
		 * For live hub jobs
		 */
		setIsViewMode(
			!!authActAs &&
				(portCallData?.jobs?.some(job => job.isLpaOperatingEntityLive) ?? false)
		);
	}, [authActAs, portCallData]);

	return (
		<UseViewModeContext.Provider value={{ isViewMode }}>
			{children}
		</UseViewModeContext.Provider>
	);
};

class ViewModeContext extends React.Component {
	static Consumer = UseViewModeContext.Consumer;
	static Provider = ViewModeProvider;

	render() {
		return null;
	}
}

export default ViewModeContext;
