import ModalContext, { UseModalContext } from './ModalContext/ModalContext';
import ViewModeContext, {
	UseViewModeContext,
	useViewModeContext
} from './ViewModeContext/ViewModeContext';

export {
	ModalContext,
	UseModalContext,
	ViewModeContext,
	UseViewModeContext,
	useViewModeContext
};
