import { FetchStatus } from 'services/api/apiTypes';
import {
	DisbursementAccount,
	PortCallFinance,
	PortCallFinanceRevision,
	DaType,
	DocumentByDaOutId,
	ServiceTemplate,
	FinanceDetails,
	FinanceServiceQueries,
	FinanceServiceError,
	CurrencyBasicType,
	DaAvailabilityItem,
	FinanceMetadataActionCode,
	DAStatusHistoryItem,
	RefreshDirectBill,
	MoveServiceSection,
	SDAReasons
} from 'services/api/finance/financeServiceTypes';
import { PortCallDocument } from 'services/api/portCallDocuments/portCallDocumentsServiceTypes';
import { Metadata } from 'app-types';

export interface FinanceState {
	readonly finance: PortCallFinance | null;
	readonly revisions: PortCallFinanceRevision[];
	readonly document: PortCallDocument | null;
	readonly financeDetails?: FinanceDetails;
	readonly serviceErrors?: FinanceServiceError[];
	readonly sdaReasons: SDAReasons[];
	readonly currencies: {
		[key: string]: CurrencyBasicType;
	};
	readonly daStatusHistoryById: {
		[key: string]: DAStatusHistoryItem[];
	};
	readonly context: {
		readonly showErrorPage: boolean;
		readonly showAddDAModal: { [key in DaType]?: boolean };
		readonly showSplitServiceModal: boolean;
		readonly editItem: DisbursementAccount | null;
		readonly setId?: string;
		readonly convertDBModalServiceId: string | null;
		readonly moveServiceModalId: string | null;
		readonly currentMoveServiceCode: string | null;
		readonly showMoveServiceModal: boolean;
		readonly splitModalServiceId: string | null;
		readonly splitModalSectionEntityId?: string;
		readonly isTabVisible?: boolean;
		readonly availableServices: {
			[id: string]: ServiceTemplate[];
		};
		readonly availableServiceCode: MoveServiceSection[];
		readonly availableDas: {
			[id: string]: {
				[key: string]: DaAvailabilityItem;
			};
		};
		readonly serviceQueries: {
			[id: string]: FinanceServiceQueries;
		};
		readonly principalServices: {
			[id: string]: ServiceTemplate[];
		};
		readonly validationErrors: {
			[id: string]: boolean;
		};
		comparisonColumn: {
			[key: string]: string;
		};
	};
	readonly documentByDaOutId: DocumentByDaOutId;
	readonly fetchStatuses: {
		retrieve: FetchStatus;
		save: FetchStatus;
		validation: FetchStatus;
		move: FetchStatus;
		moveToStage: FetchStatus;
		revertJobStatus: FetchStatus;
		approveAllServices: FetchStatus;
		addDA: FetchStatus;
		deleteDA: FetchStatus;
		retrieveSDAReason: FetchStatus;
		UpdateSDAReason: FetchStatus;
		retrieveFinanceDetails: FetchStatus;
		retrievePrincipalServices: FetchStatus;
		retrieveRevisions: FetchStatus;
		deleteService: FetchStatus;
		retrieveAvailableServices: FetchStatus;
		retrieveAvailableSections: FetchStatus;
		retrieveAvailableDas: FetchStatus;
		serviceQueries: {
			[serviceId: string]: FetchStatus;
		};
		updateBenchmark: {
			[id: string]: FetchStatus;
		};
		viewBenchmark: FetchStatus;
		updateServiceStatus: FetchStatus;
		updateFinanceStatus: FetchStatus;
		retrieveService: FetchStatus;
		retrieveServiceErrors: FetchStatus;
		retrieveFinanceCurrencies: FetchStatus;
		createDocuments: FetchStatus;
		splitService: FetchStatus;
		moveService: FetchStatus;
		reverseSplitService: FetchStatus;
		retrieveFinanceDocument: FetchStatus;
		downloadRevisionById: {
			[revisionId: string]: FetchStatus;
		};
		downloadDocumentById: {
			[document: string]: FetchStatus;
		};
		downloadDAById: {
			[daId: string]: FetchStatus;
		};
		downloadAllDAs: FetchStatus;
		deleteDocumentPageById: {
			[documentId: string]: {
				[pageNumber: string]: {
					[pageType: string]: FetchStatus;
				};
			};
		};
		cancelServiceTransaction: FetchStatus;
		cancelCreditHubRevenue: FetchStatus;
		addCreditHubRevenueService: FetchStatus;
		reopenDaSet: FetchStatus;
		retrieveDAStatusHistoryById: {
			[daId: string]: FetchStatus;
		};
		refreshBenchmark: FetchStatus;
		retrieveBenchmarkStatus: FetchStatus;
		toggleFinance: FetchStatus;
		retrieveRefreshDirectBills: FetchStatus;
		updateRefreshDirectBill: FetchStatus;
		updateFixedRateExchangeRate: FetchStatus;
		restoreOriginalDocument: FetchStatus;
		deleteFinanceDocumentOriginalAndAnnotated: FetchStatus;
		submitUnitForm: FetchStatus;
		updateUnitForm: FetchStatus;
		deleteUnitForm: FetchStatus;
		addVarianceComment: FetchStatus;
	};
	readonly addServiceStatuses: {
		[id: string]: FetchStatus;
	};
	readonly updateStatuses: {
		serviceSettings: {
			[id: string]: FetchStatus;
		};
		convertService: {
			[id: string]: FetchStatus;
		};
		convertDBStatus: {
			[id: string]: FetchStatus;
		};
		services: {
			[id: string]: FetchStatus;
		};
		das: {
			[id: string]: FetchStatus;
		};
	};
	readonly metadata: Metadata<FinanceMetadataActionCode> | null;

	// Form State
	readonly readOnly: boolean;

	readonly bltRedirectionUrl: string;

	readonly refreshDirectBill: RefreshDirectBill;
}

export const initialState: FinanceState = {
	finance: null,
	revisions: [],
	sdaReasons: [],
	document: null,
	fetchStatuses: {
		retrieve: FetchStatus.IDLE,
		save: FetchStatus.IDLE,
		validation: FetchStatus.IDLE,
		move: FetchStatus.IDLE,
		moveService: FetchStatus.IDLE,
		approveAllServices: FetchStatus.IDLE,
		addDA: FetchStatus.IDLE,
		moveToStage: FetchStatus.IDLE,
		revertJobStatus: FetchStatus.IDLE,
		deleteDA: FetchStatus.IDLE,
		retrieveSDAReason: FetchStatus.IDLE,
		UpdateSDAReason: FetchStatus.IDLE,
		retrieveFinanceDetails: FetchStatus.IDLE,
		retrievePrincipalServices: FetchStatus.IDLE,
		retrieveRevisions: FetchStatus.IDLE,
		deleteService: FetchStatus.IDLE,
		retrieveAvailableServices: FetchStatus.IDLE,
		retrieveAvailableSections: FetchStatus.IDLE,
		retrieveAvailableDas: FetchStatus.IDLE,
		serviceQueries: {},
		updateBenchmark: {},
		updateServiceStatus: FetchStatus.IDLE,
		updateFinanceStatus: FetchStatus.IDLE,
		retrieveService: FetchStatus.IDLE,
		retrieveServiceErrors: FetchStatus.IDLE,
		retrieveFinanceCurrencies: FetchStatus.IDLE,
		createDocuments: FetchStatus.IDLE,
		splitService: FetchStatus.IDLE,
		reverseSplitService: FetchStatus.IDLE,
		retrieveFinanceDocument: FetchStatus.IDLE,
		downloadRevisionById: {},
		downloadDocumentById: {},
		downloadDAById: {},
		downloadAllDAs: FetchStatus.IDLE,
		cancelServiceTransaction: FetchStatus.IDLE,
		cancelCreditHubRevenue: FetchStatus.IDLE,
		addCreditHubRevenueService: FetchStatus.IDLE,
		deleteDocumentPageById: {},
		reopenDaSet: FetchStatus.IDLE,
		retrieveDAStatusHistoryById: {},
		refreshBenchmark: FetchStatus.IDLE,
		retrieveBenchmarkStatus: FetchStatus.IDLE,
		viewBenchmark: FetchStatus.IDLE,
		toggleFinance: FetchStatus.IDLE,
		retrieveRefreshDirectBills: FetchStatus.IDLE,
		updateRefreshDirectBill: FetchStatus.IDLE,
		updateFixedRateExchangeRate: FetchStatus.IDLE,
		restoreOriginalDocument: FetchStatus.IDLE,
		deleteFinanceDocumentOriginalAndAnnotated: FetchStatus.IDLE,
		submitUnitForm: FetchStatus.IDLE,
		updateUnitForm: FetchStatus.IDLE,
		deleteUnitForm: FetchStatus.IDLE,
		addVarianceComment: FetchStatus.IDLE
	},
	documentByDaOutId: {},
	context: {
		showErrorPage: false,
		showAddDAModal: {
			[DaType.IN]: false,
			[DaType.OUT]: false
		},
		showSplitServiceModal: false,
		editItem: null,
		moveServiceModalId: null,
		currentMoveServiceCode: null,
		showMoveServiceModal: false,
		availableServiceCode: [],
		splitModalServiceId: null,
		convertDBModalServiceId: null,
		availableServices: {},
		availableDas: {},
		serviceQueries: {},
		principalServices: {},
		validationErrors: {},
		comparisonColumn: {}
	},
	addServiceStatuses: {},
	updateStatuses: {
		serviceSettings: {},
		convertService: {},
		convertDBStatus: {},
		services: {},
		das: {}
	},
	readOnly: false,
	currencies: {},
	daStatusHistoryById: {},
	metadata: null,
	bltRedirectionUrl: '',
	refreshDirectBill: {
		availableDirectBills: [],
		currentDirectBills: []
	}
};
