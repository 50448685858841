import {
	CreateVarianceCommentPayload,
	CreateVarianceCommentResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from '../financeActionCreator';

const ADD_VARIANCE_COMMENT = 'ADD_VARIANCE_COMMENT';
export const addVarianceCommentAsync = actionCreator.async<
	CreateVarianceCommentPayload,
	CreateVarianceCommentResponse,
	Error
>(ADD_VARIANCE_COMMENT, {
	templateParams: {
		entityName: 'Variance Comment'
	}
});

export const addVarianceComment = actionCreator<CreateVarianceCommentPayload>(
	ADD_VARIANCE_COMMENT
);
