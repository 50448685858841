import { onRetrieveBenchmarkStatus } from './reducers/retrieveBenchmarkStatusReducer';
import { retrieveBenchmarkStatusAsync } from './actions/retrieveBenchmarkStatus';
import { refreshBenchmarkAsync } from './actions/refreshBenchmark';
import { viewBenchmarkAsync } from './actions/viewBenchmark';
import { retrieveDAOutDocumentIdAsync } from './actions/retrieveDAOutDocumentId';
import { reducerWithInitialState } from 'typescript-fsa-reducers';
import {
	retrieveFinanceAsync,
	updateServiceAsync,
	updateDAAsync,
	validateJobFinanceAsync,
	moveJobFinanceAsync,
	moveToStageAsync
} from 'store/finance';

import {
	openAddDAModal,
	closeAddDAModal,
	addDAAsync,
	deleteDAAsync,
	retrieveFinanceDetailsAsync,
	resetFinanceDetails,
	addServiceAsync,
	deleteServiceAsync,
	openSplitServiceModal,
	splitServiceAsync,
	reverseSplitServiceAsync,
	closeSplitServiceModal,
	retrieveAvailableServicesAsync,
	retrievePrincipalServicesAsync,
	resetAvailableServices,
	updateServiceStatusAsync,
	retrieveServiceAsync,
	updateFinanceValidationErrors,
	retrieveFinanceRevisionsAsync,
	createFinanceDocumentsAsync,
	convertServiceAsync,
	retrieveFinanceDocumentAsync,
	revertJobStatusAsync,
	approveAllServicesAsync,
	downloadJobFinanceRevisionAsync,
	downloadFinanceDocumentAsync,
	downloadSingleDAAsync,
	downloadAllDAsAsync,
	deleteFinanceDocumentPageAsync,
	setComparisonColumn,
	resetFinanceContext,
	retrieveServiceQueriesAsync,
	retrieveServiceErrorsAsync,
	retrieveFinanceCurrenciesAsync,
	resetServiceQueries,
	convertToDirectBillAsync,
	convertToRegularAsync,
	openConvertDBStatusModal,
	closeConvertDBStatusModal,
	cancelServiceTransactionAsync,
	cancelCreditHubRevenueAsync,
	updateFinanceStatusAsync,
	reopenDaSetAsync,
	retrieveAvailableDasAsync,
	addCreditHubRevenueServiceAsync,
	retrieveDAStatusHistoryByIdAsync,
	resetDAStatusHistoryById,
	updateBenchmarkAsync,
	resetBltDetails,
	toggleFinanceAsync,
	retrieveRefreshDirectBillAsync,
	updateRefreshDirectBillAsync,
	updateFixedRateExchangeAsync,
	moveServiceAsync,
	retrieveAvailableSectionCodeAsync,
	openMoveServiceModal,
	closeMoveServiceModal,
	resetPrincipleServices,
	retrieveSDAReasonAsync,
	updateSDAReasonAsync,
	restoreOriginalDocumentAsync,
	deleteFinanceDocumentOriginalAndAnnotatedPageAsync,
	submitUnitCostFormAsync,
	updateUnitCostFormAsync,
	addVarianceCommentAsync
} from 'store/finance/actions';

import {
	onAddDAModalOpen,
	onAddDAModalClose,
	onAddDA,
	onDeleteDA,
	onUpdateDAStarted,
	onUpdateDASuccess,
	onUpdateDAFailed,
	onRetrieveFinanceStarted,
	onRetrieveFinanceSuccess,
	onRetrieveFinanceFailed,
	onUpdateServiceStarted,
	onUpdateServiceSuccess,
	onUpdateServiceFailed,
	onRetrieveDAOutDocumentIdStarted,
	onRetrieveDAOutDocumentIdSuccess,
	onRetrieveDAOutDocumentIdFailure,
	onAddServiceStarted,
	onAddServiceSuccess,
	onAddServiceFailure,
	onDeleteServiceStarted,
	onDeleteServiceSuccess,
	onDeleteServiceFailure,
	onSplitServiceModalOpen,
	onSplitServiceModalClose,
	onRetrievePrincipalServices,
	onResetAvailableServices,
	onUpdateServiceStatus,
	onUpdateFinanceValidationErrors,
	onRetrieveFinanceRevisions,
	onConvertServiceError,
	onConvertServiceStarted,
	onConvertServiceSuccess,
	onMoveServiceModalClose,
	onMoveServiceModalOpen,
	onMoveService,
	onRetrieveAvailableSections,
	onRetrieveFinanceDocument,
	moveToStage,
	approveAllServices,
	// Download Job Finance Revision
	onDownloadJobFinanceRevision,
	// Download Job Finance Document
	onDownloadJobFinanceDocument,
	// Download Single DA
	onDownloadSingleDA,
	// Download All DAs
	onDownloadAllDAs,
	// Delete Document Page
	onDeleteFinanceDocumentPageStarted,
	onDeleteFinanceDocumentPageSuccess,
	onDeleteFinanceDocumentPageFailed,
	// retrieve service
	onSetComparisonColumn,
	onResetFinanceContext,
	onRetrieveServiceQueriesStarted,
	onRetrieveServiceQueriesSuccess,
	onRetrieveServiceQueriesFailure,
	onResetServiceQueries,
	retrieveServiceErrors,
	retrieveFinanceCurrencies,
	// Convert to direct bill
	onConvertDBStatusStarted,
	onConvertDBStatusSuccess,
	onConvertDBStatusFailure,
	onConvertDBStatusModalOpen,
	onConvertDBStatusModalClose,
	onCancelServiceTransaction,
	onCancelCreditHubRevenue,
	onCreateFinanceDocuments,
	onValidateJobFinance,
	onMoveJobFinance,
	onRetrieveAvailableServices,
	onRetrieveFinanceDetails,
	onResetFinanceDetails,
	onResetBltDetails,
	onRetrieveService,
	onRevertJobStatus,
	onSplitService,
	onReverseSplitService,
	onAddCreditHubRevenueService,
	onUpdateBenchmarkStarted,
	onUpdateBenchmarkSuccess,
	// update finance status
	onUpdateFinanceStatus,
	onReopenDaSet,
	onRetrieveAvailableDas,
	// DA Status History
	onRetrieveDAStatusHistoryById,
	onResetDAStatusHistoryById,
	onRefreshBenchmark,
	onViewBenchmark,
	onUpdateBenchmarkFailure,
	// Toggle Finance
	onToggleFinance,
	onRetrieveRefreshDirectBill,
	onUpdateRefreshDirectBill,
	onUpdateFixedRateExchangeRate,
	onResetPrincipalServices,
	RetrieveSDAReason,
	OnUpdateSDAReason,
	onRestoreOriginalDocument,
	deleteFinanceDocumentOriginalAndAnnotatedPagesReducer,
	onSubmitUnitCostForm,
	onUpdateUnitCostForm
} from 'store/finance/reducers';

import { initialState } from './financeState';
import { onAddVarianceComment } from './reducers/addVarianceComment';

export default reducerWithInitialState(initialState)
	// retrieve finance
	.case(retrieveFinanceAsync.started, onRetrieveFinanceStarted)
	.case(retrieveFinanceAsync.done, onRetrieveFinanceSuccess)
	.case(retrieveFinanceAsync.failed, onRetrieveFinanceFailed)
	// retrieve finance details
	.case(retrieveFinanceDetailsAsync.started, onRetrieveFinanceDetails.started)
	.case(retrieveFinanceDetailsAsync.done, onRetrieveFinanceDetails.done)
	.case(retrieveFinanceDetailsAsync.failed, onRetrieveFinanceDetails.failed)
	// reset finance details
	.case(resetFinanceDetails, onResetFinanceDetails)
	// retrieve finance service
	.case(retrieveServiceAsync.started, onRetrieveService.started)
	.case(retrieveServiceAsync.done, onRetrieveService.done)
	.case(retrieveServiceAsync.failed, onRetrieveService.failed)
	// retrieve finance service queries
	.case(retrieveServiceQueriesAsync.started, onRetrieveServiceQueriesStarted)
	.case(retrieveServiceQueriesAsync.done, onRetrieveServiceQueriesSuccess)
	.case(retrieveServiceQueriesAsync.failed, onRetrieveServiceQueriesFailure)
	// reset queries
	.case(resetServiceQueries, onResetServiceQueries)
	// retrieve finance service errors
	.case(retrieveServiceErrorsAsync.started, retrieveServiceErrors.started)
	.case(retrieveServiceErrorsAsync.done, retrieveServiceErrors.done)
	.case(retrieveServiceErrorsAsync.failed, retrieveServiceErrors.failed)
	// retrieve finance currencies
	.case(
		retrieveFinanceCurrenciesAsync.started,
		retrieveFinanceCurrencies.started
	)
	.case(retrieveFinanceCurrenciesAsync.done, retrieveFinanceCurrencies.done)
	.case(retrieveFinanceCurrenciesAsync.failed, retrieveFinanceCurrencies.failed)
	// update finance service
	.case(updateServiceAsync.started, onUpdateServiceStarted)
	.case(updateServiceAsync.done, onUpdateServiceSuccess)
	.case(updateServiceAsync.failed, onUpdateServiceFailed)
	// approve all services
	.case(approveAllServicesAsync.started, approveAllServices.started)
	.case(approveAllServicesAsync.done, approveAllServices.done)
	.case(approveAllServicesAsync.failed, approveAllServices.failed)
	// update DA
	.case(updateDAAsync.started, onUpdateDAStarted)
	.case(updateDAAsync.done, onUpdateDASuccess)
	.case(updateDAAsync.failed, onUpdateDAFailed)
	// validate job finance service
	.case(validateJobFinanceAsync.started, onValidateJobFinance.started)
	.case(validateJobFinanceAsync.done, onValidateJobFinance.done)
	.case(validateJobFinanceAsync.failed, onValidateJobFinance.failed)
	// submit job finance service
	.case(moveJobFinanceAsync.started, onMoveJobFinance.started)
	.case(moveJobFinanceAsync.done, onMoveJobFinance.done)
	.case(moveJobFinanceAsync.failed, onMoveJobFinance.failed)
	// move to DA job finance service
	.case(moveToStageAsync.started, moveToStage.started)
	.case(moveToStageAsync.done, moveToStage.done)
	.case(moveToStageAsync.failed, moveToStage.failed)
	// move to DA job finance service
	.case(revertJobStatusAsync.started, onRevertJobStatus.started)
	.case(revertJobStatusAsync.done, onRevertJobStatus.done)
	.case(revertJobStatusAsync.failed, onRevertJobStatus.failed)
	// add DA
	.case(addDAAsync.started, onAddDA.started)
	.case(addDAAsync.failed, onAddDA.failed)
	.case(addDAAsync.done, onAddDA.done)
	.case(openAddDAModal, onAddDAModalOpen)
	.case(closeAddDAModal, onAddDAModalClose)
	// delete DA
	.case(deleteDAAsync.started, onDeleteDA.started)
	.case(deleteDAAsync.failed, onDeleteDA.failed)
	.case(deleteDAAsync.done, onDeleteDA.done)
	// Retrieve CoverSheet document.id
	.case(retrieveDAOutDocumentIdAsync.started, onRetrieveDAOutDocumentIdStarted)
	.case(retrieveDAOutDocumentIdAsync.done, onRetrieveDAOutDocumentIdSuccess)
	.case(retrieveDAOutDocumentIdAsync.failed, onRetrieveDAOutDocumentIdFailure)
	// add service
	.case(addServiceAsync.started, onAddServiceStarted)
	.case(addServiceAsync.done, onAddServiceSuccess)
	.case(addServiceAsync.failed, onAddServiceFailure)
	// delete service
	.case(deleteServiceAsync.started, onDeleteServiceStarted)
	.case(deleteServiceAsync.done, onDeleteServiceSuccess)
	.case(deleteServiceAsync.failed, onDeleteServiceFailure)
	// move service
	.case(
		retrieveAvailableSectionCodeAsync.started,
		onRetrieveAvailableSections.started
	)
	.case(
		retrieveAvailableSectionCodeAsync.done,
		onRetrieveAvailableSections.done
	)
	.case(
		retrieveAvailableSectionCodeAsync.failed,
		onRetrieveAvailableSections.failed
	)
	.case(openMoveServiceModal, onMoveServiceModalOpen)
	.case(closeMoveServiceModal, onMoveServiceModalClose)
	.case(moveServiceAsync.started, onMoveService.started)
	.case(moveServiceAsync.done, onMoveService.done)
	.case(moveServiceAsync.failed, onMoveService.failed)
	// split service
	.case(openSplitServiceModal, onSplitServiceModalOpen)
	.case(closeSplitServiceModal, onSplitServiceModalClose)
	.case(splitServiceAsync.started, onSplitService.started)
	.case(splitServiceAsync.done, onSplitService.done)
	.case(splitServiceAsync.failed, onSplitService.failed)
	// reverse split service
	.case(reverseSplitServiceAsync.started, onReverseSplitService.started)
	.case(reverseSplitServiceAsync.done, onReverseSplitService.done)
	.case(reverseSplitServiceAsync.failed, onReverseSplitService.failed)
	// Retrieve available services
	.case(
		retrieveAvailableServicesAsync.started,
		onRetrieveAvailableServices.started
	)
	.case(retrieveAvailableServicesAsync.done, onRetrieveAvailableServices.done)
	.case(
		retrieveAvailableServicesAsync.failed,
		onRetrieveAvailableServices.failed
	)
	// Retrieve principal services
	.case(
		retrievePrincipalServicesAsync.started,
		onRetrievePrincipalServices.started
	)
	.case(retrievePrincipalServicesAsync.done, onRetrievePrincipalServices.done)
	.case(
		retrievePrincipalServicesAsync.failed,
		onRetrievePrincipalServices.failed
	)
	// Reset
	.case(resetAvailableServices, onResetAvailableServices)
	// Update finance validation errors
	.case(updateFinanceValidationErrors, onUpdateFinanceValidationErrors)
	// service status modal
	.case(updateServiceStatusAsync.started, onUpdateServiceStatus.started)
	.case(updateServiceStatusAsync.done, onUpdateServiceStatus.done)
	.case(updateServiceStatusAsync.failed, onUpdateServiceStatus.failed)
	// finance status
	.case(updateFinanceStatusAsync.started, onUpdateFinanceStatus.started)
	.case(updateFinanceStatusAsync.done, onUpdateFinanceStatus.done)
	.case(updateFinanceStatusAsync.failed, onUpdateFinanceStatus.failed)
	// update benchmark
	.case(updateBenchmarkAsync.started, onUpdateBenchmarkStarted)
	.case(updateBenchmarkAsync.done, onUpdateBenchmarkSuccess)
	.case(updateBenchmarkAsync.failed, onUpdateBenchmarkFailure)
	// Retrieve Finance Revisions
	.case(
		retrieveFinanceRevisionsAsync.started,
		onRetrieveFinanceRevisions.started
	)
	.case(retrieveFinanceRevisionsAsync.done, onRetrieveFinanceRevisions.done)
	.case(retrieveFinanceRevisionsAsync.failed, onRetrieveFinanceRevisions.failed)
	// Create Documents
	.case(createFinanceDocumentsAsync.started, onCreateFinanceDocuments.started)
	.case(createFinanceDocumentsAsync.done, onCreateFinanceDocuments.done)
	.case(createFinanceDocumentsAsync.failed, onCreateFinanceDocuments.failed)
	// Convert from pass through
	.case(convertServiceAsync.started, onConvertServiceStarted)
	.case(convertServiceAsync.done, onConvertServiceSuccess)
	.case(convertServiceAsync.failed, onConvertServiceError)
	// Retrieve Finance Document
	.case(retrieveFinanceDocumentAsync.started, onRetrieveFinanceDocument.started)
	.case(retrieveFinanceDocumentAsync.done, onRetrieveFinanceDocument.done)
	.case(retrieveFinanceDocumentAsync.failed, onRetrieveFinanceDocument.failed)
	// Download Job Finance Revision
	.case(
		downloadJobFinanceRevisionAsync.started,
		onDownloadJobFinanceRevision.started
	)
	.case(downloadJobFinanceRevisionAsync.done, onDownloadJobFinanceRevision.done)
	.case(
		downloadJobFinanceRevisionAsync.failed,
		onDownloadJobFinanceRevision.failed
	)
	.case(
		downloadFinanceDocumentAsync.started,
		onDownloadJobFinanceDocument.started
	)
	.case(downloadFinanceDocumentAsync.done, onDownloadJobFinanceDocument.done)
	.case(
		downloadFinanceDocumentAsync.failed,
		onDownloadJobFinanceDocument.failed
	)
	// Download Single DA
	.case(downloadSingleDAAsync.started, onDownloadSingleDA.started)
	.case(downloadSingleDAAsync.done, onDownloadSingleDA.done)
	.case(downloadSingleDAAsync.failed, onDownloadSingleDA.failed)
	// Download All DAs
	.case(downloadAllDAsAsync.started, onDownloadAllDAs.started)
	.case(downloadAllDAsAsync.done, onDownloadAllDAs.done)
	.case(downloadAllDAsAsync.failed, onDownloadAllDAs.failed)
	// Delete Document Page
	.case(
		deleteFinanceDocumentPageAsync.started,
		onDeleteFinanceDocumentPageStarted
	)
	.case(deleteFinanceDocumentPageAsync.done, onDeleteFinanceDocumentPageSuccess)
	.case(
		deleteFinanceDocumentPageAsync.failed,
		onDeleteFinanceDocumentPageFailed
	)
	.case(
		deleteFinanceDocumentOriginalAndAnnotatedPageAsync.started,
		deleteFinanceDocumentOriginalAndAnnotatedPagesReducer.started
	)
	.case(
		deleteFinanceDocumentOriginalAndAnnotatedPageAsync.done,
		deleteFinanceDocumentOriginalAndAnnotatedPagesReducer.done
	)
	.case(
		deleteFinanceDocumentOriginalAndAnnotatedPageAsync.failed,
		deleteFinanceDocumentOriginalAndAnnotatedPagesReducer.failed
	)
	// Set comparison column
	.case(setComparisonColumn, onSetComparisonColumn)
	// Reset finance context when we unmount finance page
	.case(resetFinanceContext, onResetFinanceContext)
	// Convert to direct bill
	// Convert to direct bill and to regular modal
	.case(openConvertDBStatusModal, onConvertDBStatusModalOpen)
	.case(closeConvertDBStatusModal, onConvertDBStatusModalClose)
	.case(convertToDirectBillAsync.started, onConvertDBStatusStarted)
	.case(convertToDirectBillAsync.done, onConvertDBStatusSuccess)
	.case(convertToDirectBillAsync.failed, onConvertDBStatusFailure)
	.case(convertToRegularAsync.started, onConvertDBStatusStarted)
	.case(convertToRegularAsync.done, onConvertDBStatusSuccess)
	.case(convertToRegularAsync.failed, onConvertDBStatusFailure)
	.case(
		addCreditHubRevenueServiceAsync.started,
		onAddCreditHubRevenueService.started
	)
	.case(addCreditHubRevenueServiceAsync.done, onAddCreditHubRevenueService.done)
	.case(
		addCreditHubRevenueServiceAsync.failed,
		onAddCreditHubRevenueService.failed
	)
	// cancel service transaction
	.case(
		cancelServiceTransactionAsync.started,
		onCancelServiceTransaction.started
	)
	.case(cancelServiceTransactionAsync.done, onCancelServiceTransaction.done)
	.case(cancelServiceTransactionAsync.failed, onCancelServiceTransaction.failed)
	.case(cancelCreditHubRevenueAsync.started, onCancelCreditHubRevenue.started)
	.case(cancelCreditHubRevenueAsync.done, onCancelCreditHubRevenue.done)
	.case(cancelCreditHubRevenueAsync.failed, onCancelCreditHubRevenue.failed)
	.case(reopenDaSetAsync.started, onReopenDaSet.started)
	.case(reopenDaSetAsync.done, onReopenDaSet.done)
	.case(reopenDaSetAsync.failed, onReopenDaSet.failed)
	// Retrieve available das
	.case(retrieveAvailableDasAsync.started, onRetrieveAvailableDas.started)
	.case(retrieveAvailableDasAsync.done, onRetrieveAvailableDas.done)
	.case(retrieveAvailableDasAsync.failed, onRetrieveAvailableDas.failed)
	.case(retrieveSDAReasonAsync.started, RetrieveSDAReason.started)
	.case(retrieveSDAReasonAsync.done, RetrieveSDAReason.done)
	.case(retrieveSDAReasonAsync.failed, RetrieveSDAReason.failed)
	.case(updateSDAReasonAsync.started, OnUpdateSDAReason.started)
	.case(updateSDAReasonAsync.done, OnUpdateSDAReason.done)
	.case(updateSDAReasonAsync.failed, OnUpdateSDAReason.failed)
	// Retrieve DA status history
	.case(
		retrieveDAStatusHistoryByIdAsync.started,
		onRetrieveDAStatusHistoryById.started
	)
	.case(
		retrieveDAStatusHistoryByIdAsync.done,
		onRetrieveDAStatusHistoryById.done
	)
	.case(
		retrieveDAStatusHistoryByIdAsync.failed,
		onRetrieveDAStatusHistoryById.failed
	)
	.case(resetDAStatusHistoryById, onResetDAStatusHistoryById)
	// Refresh benchmark
	.case(refreshBenchmarkAsync.started, onRefreshBenchmark.started)
	.case(refreshBenchmarkAsync.done, onRefreshBenchmark.done)
	.case(refreshBenchmarkAsync.failed, onRefreshBenchmark.failed)
	// View benchmark
	.case(viewBenchmarkAsync.started, onViewBenchmark.started)
	.case(viewBenchmarkAsync.done, onViewBenchmark.done)
	.case(viewBenchmarkAsync.failed, onViewBenchmark.failed)
	// reset blt details
	.case(resetBltDetails, onResetBltDetails)
	// Retrieve benchmark status
	.case(retrieveBenchmarkStatusAsync.started, onRetrieveBenchmarkStatus.started)
	.case(retrieveBenchmarkStatusAsync.done, onRetrieveBenchmarkStatus.done)
	.case(retrieveBenchmarkStatusAsync.failed, onRetrieveBenchmarkStatus.failed)
	// Toggle finance
	.case(toggleFinanceAsync.started, onToggleFinance.started)
	.case(toggleFinanceAsync.done, onToggleFinance.done)
	.case(toggleFinanceAsync.failed, onToggleFinance.failed)
	// Retrieve Refresh DirectBill
	.case(
		retrieveRefreshDirectBillAsync.started,
		onRetrieveRefreshDirectBill.started
	)
	.case(retrieveRefreshDirectBillAsync.done, onRetrieveRefreshDirectBill.done)
	.case(
		retrieveRefreshDirectBillAsync.failed,
		onRetrieveRefreshDirectBill.failed
	)
	// Update Refresh DirectBill
	.case(updateRefreshDirectBillAsync.started, onUpdateRefreshDirectBill.started)
	.case(updateRefreshDirectBillAsync.done, onUpdateRefreshDirectBill.done)
	.case(updateRefreshDirectBillAsync.failed, onUpdateRefreshDirectBill.failed)

	// Update FixedRate Exchange
	.case(
		updateFixedRateExchangeAsync.started,
		onUpdateFixedRateExchangeRate.started
	)
	.case(updateFixedRateExchangeAsync.done, onUpdateFixedRateExchangeRate.done)
	.case(
		updateFixedRateExchangeAsync.failed,
		onUpdateFixedRateExchangeRate.failed
	)
	.case(resetPrincipleServices, onResetPrincipalServices)
	// Restore original document
	.case(restoreOriginalDocumentAsync.started, onRestoreOriginalDocument.started)
	.case(restoreOriginalDocumentAsync.done, onRestoreOriginalDocument.done)
	.case(restoreOriginalDocumentAsync.failed, onRestoreOriginalDocument.failed)

	.case(submitUnitCostFormAsync.started, onSubmitUnitCostForm.started)
	.case(submitUnitCostFormAsync.done, onSubmitUnitCostForm.done)
	.case(submitUnitCostFormAsync.failed, onSubmitUnitCostForm.failed)

	.case(updateUnitCostFormAsync.started, onUpdateUnitCostForm.started)
	.case(updateUnitCostFormAsync.done, onUpdateUnitCostForm.done)
	.case(updateUnitCostFormAsync.failed, onUpdateUnitCostForm.failed)
	// Add Service Variance Comment
	.case(addVarianceCommentAsync.started, onAddVarianceComment.started)
	.case(addVarianceCommentAsync.done, onAddVarianceComment.done)
	.case(addVarianceCommentAsync.failed, onAddVarianceComment.failed);
