import { all, fork } from 'redux-saga/effects';
import { updateServiceStatusWatcher } from './updateServiceStatusSaga';
import { convertServiceWatcher } from './convertServiceSaga';
import addDAWatcher from './addDA';
import addServiceWatcher from './addService';
import deleteDAWatcher from './deleteDA';
import deleteServiceWatcher from './deleteService';
import addDAOutWatcher from './addDAOut';
import deleteDAOutWatcher from './deleteDAOut';
import reactToChangesForOperationsWatcher from './reactToChangesForFinancesSaga';
import retrieveAvailableServicesWatcher from './retrieveAvailableServices';
import retrieveDAOutDocumentId from './retrieveDAOutDocumentIdSaga';
import retrieveFinanceRevisionsWatcher from './retrieveFinanceRevisionsSaga';
import retrieveFinanceWatcher from './retrieveFinanceSaga';
import retrieveFinanceCycleSaga from './retrieveFinanceCycleSaga';
import retrieveFinanceDetailsSaga from './retrieveFinanceDetailsSaga';
import retrieveFinanceDetailsCycleSaga from './retrieveFinanceDetailsCycleSaga';
import retrievePrincipalServicesSaga from './retrievePrincipalServicesSaga';
import validateJobFinanceSaga from './validateJobFinanceSaga';
import moveJobFinanceSaga from './moveJobFinanceSaga';
import moveToStageWatcher from './moveToStageSaga';
import revertJobStatusWatcher from './revertJobStatusSaga';
import approveAllServicesWatcher from './approveAllServices';
import updateDASaga from './updateDASaga';
import updateServiceSaga from './updateServiceSaga';
import updateBenchmarkSaga from './updateBenchmarkSaga';
import retrieveServiceWatcher from './retrieveServiceSaga';
import retrieveServiceQueriesWatcher from './retrieveServiceQueriesSaga';
import retrieveServiceErrorsWatcher from './retrieveServiceErrorsSaga';
import retrieveFinanceCurrenciesWatcher from './retrieveFinanceCurrenciesSaga';
import uploadFinanceDocumentationDoneWatcher from './uploadFinanceDocumentationDoneSaga';
import uploadAnnotatedDocumentDoneWatcher from './uploadAnnotatedDocumentDoneSaga';
import retrieveFinanceServicesForContextualizationWatcher from './retrieveFinanceServicesForContextualizationSaga';
import createFinanceDocumentsWatcher from './createFinanceDocuments/createFinanceDocumentsSaga';
import splitServiceWatcher from './splitServiceSaga';
import reverseSplitServiceWatcher from './reverseSplitServiceSaga';
import moveServiceWatcher from './moveServiceSaga';
import retrieveAvailableSectionWatcher from './retrieveAvailableSectionCodeSaga';
import retrieveFinanceDocumentWatcher from './retrieveFinanceDocumentSaga';
import downloadFinanceDocumentWatcher from './downloadFinanceDocumentSaga';
import downloadJobFinanceRevisionWatcher from './downloadJobFinanceRevisionSaga';
import downloadSingleDAWatcher from './downloadSingleDASaga';
import downloadAllDAsWatcher from './downloadAllDAsSaga';
import deleteFinanceDocumentPageWatcher from './deleteFinanceDocumentPageSaga';
import deleteFinanceDocumentWatcher from './deleteFinanceDocumentSaga';
import convertToDirectBillWatcher from './convertToDirectBillSaga';
import convertToRegularWatcher from './convertToRegularSaga';
import cancelServiceTransactionWatcher from './cancelServiceTransactionSaga';
import cancelCreditHubRevenueWatcher from './cancelCreditHubRevenueSaga';
import updateFinanceStatusWatcher from './updateFinanceStatusSaga';
import reopenDaSetWatcher from './reopenDaSetSaga';
import retrieveAvailableDasWatcher from './retrieveAvailableDasSaga';
import addCreditHubRevenueServiceWatcher from './addCreditHubRevenueServiceSaga';
import retrieveDAStatusHistoryByIdWatcher from './retrieveDAStatusHistoryByIdSaga';
import refreshBenchmarkWatcher from './refreshBenchmarkSaga';
import viewBenchmarkWatcher from './viewBenchmarkSaga';
import retrieveBenchmarkStatusWatcher from './retrieveBenchmarkStatusSaga';
import retrieveBenchmarkStatusCycleWatcher from './retrieveBenchmarkStatusCycleSaga';
import retrieveSDAReasonWatcher from './retrieveSDAReasonSaga';
import updateSDAReasonWatcher from './updateSDAReasonSaga';
import toggleFinanceSagaWatcher from './toggleFinanceSaga';
import retrieveRefreshDirectBillWatcher from './retrieveRefreshDirectBillSaga';
import updateRefreshDirectBillWatcher from './updateRefreshDirectBillSaga';
import updateFixedRateExchangeWatcher from './updateFixedRateExchangeSaga';
import restoreOriginalDocumentWatcher from './restoreOriginalDocumentSaga';
import deleteFinanceDocumentOriginalAndAnnotatedPagesWatcher from './deleteFinanceDocumentOriginalAndAnnotatedPagesSaga';
import submitUnitFormSaga from './submitUnitFormSaga';
import updateUnitFormSaga from './updateUnitFormSaga';
import deleteUnitFormSaga from './deleteUnitFormSaga';
import addVarianceCommentWatcher from './addVarianceComment';

export default function*() {
	yield all([
		fork(addDAWatcher),
		fork(addDAOutWatcher),
		fork(deleteDAOutWatcher),
		fork(addServiceWatcher),
		fork(deleteDAWatcher),
		fork(deleteServiceWatcher),
		fork(reactToChangesForOperationsWatcher),
		fork(retrieveAvailableServicesWatcher),
		fork(retrieveDAOutDocumentId),
		fork(retrieveFinanceRevisionsWatcher),
		fork(retrieveFinanceWatcher),
		fork(retrieveFinanceCycleSaga),
		fork(retrieveFinanceDetailsSaga),
		fork(retrieveFinanceDetailsCycleSaga),
		fork(retrievePrincipalServicesSaga),
		fork(validateJobFinanceSaga),
		fork(moveJobFinanceSaga),
		fork(moveToStageWatcher),
		fork(revertJobStatusWatcher),
		fork(approveAllServicesWatcher),
		fork(updateDASaga),
		fork(updateServiceSaga),
		fork(updateBenchmarkSaga),
		fork(retrieveServiceWatcher),
		fork(retrieveServiceQueriesWatcher),
		fork(retrieveServiceErrorsWatcher),
		fork(retrieveFinanceCurrenciesWatcher),
		fork(updateServiceStatusWatcher),
		fork(uploadFinanceDocumentationDoneWatcher),
		fork(retrieveFinanceServicesForContextualizationWatcher),
		fork(convertServiceWatcher),
		fork(createFinanceDocumentsWatcher),
		fork(splitServiceWatcher),
		fork(moveServiceWatcher),
		fork(retrieveAvailableSectionWatcher),
		fork(reverseSplitServiceWatcher),
		fork(retrieveFinanceDocumentWatcher),
		fork(downloadFinanceDocumentWatcher),
		fork(downloadJobFinanceRevisionWatcher),
		fork(downloadSingleDAWatcher),
		fork(downloadAllDAsWatcher),
		fork(deleteFinanceDocumentPageWatcher),
		fork(deleteFinanceDocumentWatcher),
		fork(convertToDirectBillWatcher),
		fork(convertToRegularWatcher),
		fork(cancelServiceTransactionWatcher),
		fork(cancelCreditHubRevenueWatcher),
		fork(updateFinanceStatusWatcher),
		fork(reopenDaSetWatcher),
		fork(retrieveAvailableDasWatcher),
		fork(addCreditHubRevenueServiceWatcher),
		fork(retrieveDAStatusHistoryByIdWatcher),
		fork(refreshBenchmarkWatcher),
		fork(viewBenchmarkWatcher),
		fork(retrieveBenchmarkStatusWatcher),
		fork(retrieveBenchmarkStatusCycleWatcher),
		fork(toggleFinanceSagaWatcher),
		fork(retrieveRefreshDirectBillWatcher),
		fork(retrieveSDAReasonWatcher),
		fork(updateSDAReasonWatcher),
		fork(updateRefreshDirectBillWatcher),
		fork(updateFixedRateExchangeWatcher),
		fork(uploadAnnotatedDocumentDoneWatcher),
		fork(restoreOriginalDocumentWatcher),
		fork(deleteFinanceDocumentOriginalAndAnnotatedPagesWatcher),
		fork(submitUnitFormSaga),
		fork(updateUnitFormSaga),
		fork(deleteUnitFormSaga),
		fork(addVarianceCommentWatcher)
	]);
}
