import {
	SplitServiceRequestPayload,
	SplitServiceResponse
} from 'services/api/finance/financeServiceTypes';
import actionCreator from 'store/finance/financeActionCreator';
export interface OpenSplitServiceModalPayload {
	serviceId: string;
	sectionEntityId: string;
}

const SPLIT_SERVICE = 'SPLIT_SERVICE';
const SPLIT_SERVICE_MODAL_OPEN = 'SPLIT_SERVICE_MODAL_OPEN';
const SPLIT_SERVICE_MODAL_CLOSE = 'SPLIT_SERVICE_MODAL_CLOSE';

export const splitServiceAsync = actionCreator.async<
	SplitServiceRequestPayload,
	SplitServiceResponse,
	Error
>(SPLIT_SERVICE, {
	templateParams: {
		entityName: 'Split/Rearrange'
	}
});

export const splitService = actionCreator<SplitServiceRequestPayload>(
	SPLIT_SERVICE
);
export const openSplitServiceModal = actionCreator<
	OpenSplitServiceModalPayload
>(SPLIT_SERVICE_MODAL_OPEN);
export const closeSplitServiceModal = actionCreator(SPLIT_SERVICE_MODAL_CLOSE);
